<template>
  <div>
    <div class="flix-panel flix-panel-default">
      <div class="flix-panel-heading">
        <div v-if="$route.params.action === 'overview'">Schritt 1</div>
        <div v-else-if="$route.params.action === 'switch'">Organisation wählen</div>
        <div v-else-if="$route.params.action === 'new'">{{ $t('message.createNew', {name: $tc('message.organisation', 1)}) }}</div>
        <div v-else>{{ $t('message.edit', {name: $tc('message.organisation', 1)}) }}</div>
      </div>
      <div class="flix-panel-body">
        <transition name="flixFadeIn">
          <div :key="$route.params.action">
            <slot />
          </div>
        </transition>
      </div>
      <div class="flix-panel-footer" v-if="$route.params.action !== 'overview'">
        <flixCheckPermissions role="profile" permission="3">
            <router-link :to="{ name: 'dashboardPermissions', params: { part: 'organisations', action: 'overview' } }" class="flix-html-a">
              {{ $t('message.back', {name: ''}) }}
            </router-link>
        </flixCheckPermissions>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  ul, li
    list-style-type: numeric
    padding: 0
  ul
    padding: 0 10px
    display: flex
    align-items: stretch
    justify-content: flex-start
    gap: 30px
    flex-wrap: wrap
    @media (max-width: 500px)
      flex-direction: column
      gap: 10px
      align-items: center
    li
      flex: 1
      text-transform: uppercase

</style>
